import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
//
export var getAllProductsReducer = function getAllProductsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    products: [],
    count: 0,
    loading: true
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'GET_ALL_PRODUCTS_REQUEST':
      return _defineProperty({
        loading: true,
        products: [],
        count: 0
      }, "loading", true);

    case 'GET_ALL_PRODUCTS_SUCCESS':
      return {
        loading: false,
        products: action.payload.products,
        count: action.payload.count
      };

    case 'GET_ALL_PRODUCTS_FAIL':
      return {
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
export var getAProductReducer = function getAProductReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    product: {},
    loading: true
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'GET_A_PRODUCT_REQUEST':
      return {
        loading: true,
        product: {}
      };

    case 'GET_A_PRODUCT_SUCCESS':
      return {
        loading: false,
        product: action.payload
      };

    case 'GET_A_PRODUCT_FAIL':
      return {
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
export var adminSearchProductReducer = function adminSearchProductReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    product: {},
    loading: true
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'ADMIN_SEARCH_PRODUCT_REQUEST':
      return {
        loading: true,
        product: {}
      };

    case 'ADMIN_SEARCH_PRODUCT_SUCCESS':
      return {
        loading: false,
        product: action.payload
      };

    case 'ADMIN_SEARCH_PRODUCT_FAIL':
      return {
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
export var getCategoryProductReducer = function getCategoryProductReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    products: [],
    loading: true
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'GET_CATEGORY_PRODUCT_REQUEST':
      return {
        loading: true,
        products: []
      };

    case 'GET_CATEGORY_PRODUCT_SUCCESS':
      return {
        loading: false,
        products: action.payload
      };

    case 'GET_CATEGORY_PRODUCT_FAIL':
      return {
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};