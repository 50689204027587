var initialState = {
  token: '',
  loggedIn: false
};

var practiceScoreReducer = function practiceScoreReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'LOG_IN':
      state.token = action.payload.token;
      state.loggedIn = true;
      return state;

    case 'LOG_OUT':
      state.token = '';
      state.loggedIn = false;
      return state;

    default:
      return state;
  }
};

export default practiceScoreReducer;