import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
var ISSERVER = false;
var cartItemsFromStorage = !ISSERVER && localStorage.getItem('vincentcart') ? JSON.parse(localStorage.getItem('vincentcart')) : [];
var tokenFromStorage = !ISSERVER && localStorage.getItem('vincenttoken') ? JSON.parse(localStorage.getItem('vincenttoken')) : null;
var favItemsFromStorage = !ISSERVER && localStorage.getItem('vincentfav') ? JSON.parse(localStorage.getItem('vincentfav')) : [];
var initialState = {
  cart: {
    cartItems: cartItemsFromStorage
  },
  token: tokenFromStorage,
  favItems: {
    favItems: favItemsFromStorage
  }
};
var store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
export default store;