import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var cartReducer = function cartReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    cartItems: [],
    shippingAddress: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'CART_ADD_ITEM':
      var item = action.payload;
      var existItem = state.cartItems.find(function (x) {
        return x.product === item.product;
      });

      if (existItem) {
        return _objectSpread(_objectSpread({}, state), {}, {
          cartItems: state.cartItems.map(function (x) {
            return x.product === existItem.product ? item : x;
          })
        });
      } else {
        return _objectSpread(_objectSpread({}, state), {}, {
          cartItems: [].concat(_toConsumableArray(state.cartItems), [item])
        });
      }

    case 'CART_REMOVE_ITEM':
      return _objectSpread(_objectSpread({}, state), {}, {
        cartItems: state.cartItems.filter(function (x) {
          return x.product !== action.payload;
        })
      });

    case 'CART_SAVE_SHIPPING_ADDRESS':
      return _objectSpread(_objectSpread({}, state), {}, {
        shippingAddress: action.payload
      });

    case 'CART_SAVE_PAYMENT_METHOD':
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentMethod: action.payload
      });

    case 'CART_CLEAR_ITEMS':
      return _objectSpread(_objectSpread({}, state), {}, {
        cartItems: []
      });

    default:
      return state;
  }
};