import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
export var favItemReducer = function favItemReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    favItems: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'ADD_FAV_ITEM':
      var existItem = state.favItems.find(function (x) {
        return x === action.payload;
      });

      if (existItem) {
        return _objectSpread({}, state);
      } else {
        return _objectSpread(_objectSpread({}, state), {}, {
          favItems: [].concat(_toConsumableArray(state.favItems), [action.payload])
        });
      }

    case 'REMOVE_FAV_ITEM':
      return _objectSpread(_objectSpread({}, state), {}, {
        favItems: state.favItems.filter(function (x) {
          return x !== action.payload;
        })
      });

    case 'RESET_FAV_ICON':
      return {
        favItems: action.payload
      };

    case 'REMOVE_FAV_ICON':
      return {
        favItems: []
      };

    default:
      return state;
  }
};